import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import React, { useEffect, useContext, useState } from "react";

import SnackbarContext from "../../contexts/Snackbar";
import requestAdapter from "../../api/request";

import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import PropTypes from "prop-types";
import ButtonRounded from "../../components/ButtonRounded";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="primeira página"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="página anterior"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="próxima página"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="última página"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function MyTransactions() {
  const { setSnack } = useContext(SnackbarContext);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [code, setCode] = useState("");
  const [initalDate, setInitialDate] = useState(getInitialDate());
  const [finalDate, setFinalDate] = useState(getFinalDate());
  const [input, setInput] = useState(true);
  const [output, setOutput] = useState(true);
  const [taxes, setTaxes] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [transactionDetail, setTransactionDetail] = useState({});
  const [money, setMoney] = useState(null);

  function two(num) {
    if (num < 10) return "0" + num;
    return num;
  }
  function getFinalDate() {
    const d = new Date();
    return (
      d.getFullYear() + "-" + two(d.getMonth() + 1) + "-" + two(d.getDate())
    );
  }

  function getInitialDate() {
    var d = new Date();
    d.setMonth(d.getMonth() - 1);
    return (
      d.getFullYear() + "-" + two(d.getMonth() + 1) + "-" + two(d.getDate())
    );
  }

  const handleClickOpen = async (id) => {
    try {
      const res = await requestAdapter.get(`/transaction-detail/${id}`);
      if (res.status === 200) {
        if (res.data) {
          setTransactionDetail(res.data.data[0]);
          setOpen(true);
        }
      }
    } catch {
      setSnack({
        message: "Ocorreu um erro, tente novamente",
        open: true,
        severity: "error",
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getData = async () => {
    try {
      const res = await requestAdapter.get(
        `/transactions?&start=${initalDate}&final=${finalDate}`
      );
      if (res.status === 200) {
        if (res.data) {
          let rows = res.data.data;

          rows = rows.sort((a, b) => (a.entryDate > b.entryDate ? -1 : 1));

          setRows(rows);
          let aux = rows;
          if (!input) {
            aux = aux.filter((a) => a.type !== "C");
          }
          if (!output) {
            aux = aux.filter((a) => a.type !== "D");
          }
          if (!taxes) {
            aux = aux.filter((a) => a.type === "S");
          }
          if (code) {
            aux = aux.filter((a) => a.historyCode + "" === code);
          }
          // if (initalDate) {
          //   aux = aux.filter((a) => a.creditDate.split("T")[0] >= initalDate);
          // }
          // if (finalDate) {
          //   aux = aux.filter((a) => a.creditDate.split("T")[0] <= finalDate);
          // }
          handleChangePage({}, 0);
          setFilteredRows(aux);
        }
      }
    } catch (ex) {
      setSnack({
        message: "Ocorreu um erro, tente novamente",
        open: true,
        severity: "error",
      });
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await requestAdapter.get(`/transactions/balance`);
        if (res.status === 200) {
          if (res.data) {
            setMoney(res.data.data.balance);
          }
        }
      } catch (ex) {
        setSnack({
          message: "Ocorreu um erro, tente novamente",
          open: true,
          severity: "error",
        });
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function formatDate(d) {
    if (!d) return "";
    const x = d.split("T");
    const data = x[0].split("-");
    return `${data[2]}/${data[1]}/${data[0]} ${x[1].split(".")[0]}`;
  }

  function search() {
    getData();
  }

  const sumAll = (type) => {
    let val = 0;
    for (const t of filteredRows) {
      if (t.type === type) {
        if (
          t.description.indexOf("TARIFA PIX DEB") !== -1 ||
          t.description.indexOf("DEBITO DE TARIFA PIX1") !== -1 ||
          t.description.indexOf("DEBITO TAXA DE SERVICO") !== -1
        )
          continue;
        val += Number(t.amount);
      }
    }
    return val.toLocaleString("pt-br", { style: "currency", currency: "BRL" });
  };

  const sumAllFee = (type) => {
    let val = 0;
    for (const t of filteredRows) {
      if (t.type === type) {
        if (
          type === "D" &&
          (t.description.indexOf("TARIFA PIX DEB") !== -1 ||
            t.description.indexOf("DEBITO TAXA DE SERVICO") !== -1)
        )
          val += Number(t.amount);
      } else if (
        type === "C" &&
        (t.description.indexOf("DEBITO DE TARIFA PIX1") !== -1 ||
          t.description.indexOf("DEBITO TAXA DE SERVICO") !== -1)
      )
        val += Number(t.amount);
    }
    return val.toLocaleString("pt-br", { style: "currency", currency: "BRL" });
  };

  const ticket = (type) => {
    const op = filteredRows.filter((value) => value.type === type).length;
    if (!op) return "R$ 0,00";
    let val = 0;
    for (const t of filteredRows) {
      if (t.type === type) val += Number(t.amount);
    }
    return (val / op).toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  };

  const getNumberOfTransaaction = (type) => {
    if (type === "C") {
      return filteredRows.filter((value) => value.type === type).length;
    }
    let ans = 0;
    for (const t of filteredRows) {
      if (t.type === type) {
        if (
          t.description.indexOf("TARIFA PIX DEB") !== -1 ||
          t.description.indexOf("DEBITO DE TARIFA PIX1") !== -1 ||
          t.description.indexOf("DEBITO TAXA DE SERVICO") !== -1
        )
          ans += 1;
      }
    }
    return ans;
  };

  function downloadExcel() {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    // console.log(rows);
    const json = [];
    for (const r of rows) {
      json.push({
        Código: r.historyCode,
        Data: formatDate(r.entryDate),
        Tipo: r.type === "C" ? "Entrada" : r.type === "D" ? "Saída" : "Saldo",
        Descrição: r.description,
        Valor:
          (r.type === "C" ? "+ " : r.type === "D" ? "- " : " ") +
          `R$ ${r.amount.toFixed(2)}`,
      });
    }

    const ws = XLSX.utils.json_to_sheet(json);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Transaçõees " + fileExtension);
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ minWidth: "600px" }}>
          <DialogContentText id="alert-dialog-description">
            <Typography style={{ textAlign: "center" }} variant="body1">
              <b style={{ fontWeight: "bold" }}>Transações - Detalhamento</b>
            </Typography>
            <Divider
              style={{
                background: "var(--color-orange-mid)",
                width: "20%",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: 20,
                textAlign: "center",
              }}
            />
            {!transactionDetail && (
              <Typography style={{ textAlign: "center" }} variant="body1">
                Não foi possível encontrar a transação selecionada
              </Typography>
            )}
            {transactionDetail && (
              <Typography variant="body2">
                Data da transação:{" "}
                <b style={{ fontWeight: "bold" }}>
                  {formatDate(transactionDetail.createdAt)}
                </b>
                <br />
                Tipo da transação:{" "}
                <b style={{ fontWeight: "bold" }}>
                  {transactionDetail.type === "C" ? "Entrada" : "Saída"}
                </b>
                <br />
                Valor da transação:{" "}
                <b style={{ fontWeight: "bold" }}>
                  {transactionDetail.type === "C" ? "+" : "-"}R${" "}
                  {Number(transactionDetail.totalAmount).toFixed(2)}
                </b>
                <br />
                {transactionDetail.fee && (
                  <span>
                    Taxas:{" "}
                    <b style={{ fontWeight: "bold" }}>
                      -R$ {transactionDetail.fee}
                    </b>
                    <br />
                  </span>
                )}
                {transactionDetail.type === "C" && (
                  <span>
                    Recebido de:{" "}
                    <b style={{ fontWeight: "bold" }}>
                      {transactionDetail.sender_name} -{" "}
                      {transactionDetail.sender_taxidmasked} /{" "}
                      {transactionDetail.sender_pspname}
                    </b>
                    <br />
                  </span>
                )}
                {transactionDetail.type === "D" && (
                  <span>
                    Enviado para:{" "}
                    <b style={{ fontWeight: "bold" }}>
                      {transactionDetail.receiver_name} -{" "}
                      {transactionDetail.receiver_taxidmasked} /{" "}
                      {transactionDetail.receiver_pspname}
                    </b>
                    <br />
                  </span>
                )}
              </Typography>
            )}
            <Grid
              container
              style={{ paddingTop: 10, justifyContent: "center" }}
            >
              <Grid item>
                <ButtonRounded
                  onClick={handleClose}
                  text={"Fechar"}
                ></ButtonRounded>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Grid
        container
        justify="center"
        style={{ paddingTop: "25px", textAlign: "center" }}
      >
        <Grid item xs={12}>
          <Typography variant="h5">Últimas Transações</Typography>
        </Grid>

        <Divider
          style={{
            background: "var(--color-orange-mid)",
            width: "20%",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 20,
            textAlign: "center",
          }}
        />
      </Grid>

      <Grid
        container
        spacing={1}
        style={{ marginBottom: 10, alignItems: "center" }}
      >
        <Grid item xs={2}>
          <TextField
            fullWidth
            size="small"
            type="text"
            onChange={(ev) => setCode(ev.target.value)}
            variant="outlined"
            label="Código"
            name="code"
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            id="initialDate"
            onChange={(ev) => setInitialDate(ev.target.value)}
            value={initalDate}
            label="Data inicial"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            id="initialDate"
            onChange={(ev) => setFinalDate(ev.target.value)}
            label="Data final"
            value={finalDate}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <b style={{ fontWeight: "bold" }}>Filtrar por:</b>
          <FormControlLabel
            style={{ paddingLeft: 10 }}
            control={
              <Checkbox
                variant="outlined"
                checked={input}
                color="primary"
                name="entrada"
                onChange={() => setInput(!input)}
              />
            }
            label="Entradas"
          />
          <FormControlLabel
            style={{ paddingLeft: 10 }}
            control={
              <Checkbox
                variant="outlined"
                checked={output}
                color="primary"
                name="saidas"
                onChange={() => setOutput(!output)}
              />
            }
            label="Saídas"
          />
          <FormControlLabel
            style={{ paddingLeft: 10 }}
            control={
              <Checkbox
                variant="outlined"
                checked={taxes}
                color="primary"
                name="tarifas"
                onChange={() => setTaxes(!taxes)}
              />
            }
            label="Saldo"
          />
        </Grid>

        <Grid item xs={1}>
          <ButtonRounded onClick={search} text={"Buscar"}></ButtonRounded>
        </Grid>
      </Grid>

      <Divider
        style={{
          background: "var(--color-orange-mid)",
          width: "20%",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: 20,
          textAlign: "center",
        }}
      />
      {money !== null && (
        <Grid container style={{ justifyContent: "flex-end" }}>
          <Grid item>
            Saldo disponível em conta:{" "}
            <b style={{ fontSize: 20, color: "var(--color-orange-mid)" }}>
              R$ {Number(money).toFixed(2)}
            </b>
          </Grid>
        </Grid>
      )}
      {rows && rows.length > 0 && (
        <Grid container style={{ justifyContent: "flex-end" }}>
          <Grid item>
            <span
              onClick={downloadExcel}
              style={{
                cursor: "pointer",
                fontWeight: "bold",
                textDecorationLine: "underline",
              }}
            >
              Exportar dados
            </span>
          </Grid>
        </Grid>
      )}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Código</TableCell>
              <TableCell align="left">Data</TableCell>
              <TableCell align="left">Tipo</TableCell>
              <TableCell align="left">Descrição</TableCell>

              <TableCell align="right">Valor</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredRows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : filteredRows
            ).map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.historyCode}
                </TableCell>

                <TableCell align="left">{formatDate(row.entryDate)}</TableCell>
                <TableCell align="left">
                  {row.type === "C"
                    ? "Entrada"
                    : row.type === "D"
                    ? "Saída"
                    : "Saldo"}
                </TableCell>
                <TableCell align="left">{row.description}</TableCell>

                <TableCell
                  align="right"
                  // style={
                  //   row.type === "C"
                  //     ? { color: "green" }
                  //     : row.type === "D"
                  //     ? { color: "red" }
                  //     : { textWeight: "bold" }
                  // }
                >
                  {(row.type === "C" ? "+ " : row.type === "D" ? "- " : " ") +
                    `R$ ${row.amount.toFixed(2)}`}
                </TableCell>
                <TableCell>
                  {(row.type === "C" || row.type === "D") && (
                    <IconButton
                      aria-label="Sair do sistema"
                      color="inherit"
                      onClick={() => handleClickOpen(row.transactionId)}
                    >
                      <Visibility />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "Todos", value: -1 }]}
                colSpan={3}
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "Linhas por página" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={"Linhas por página"}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <div
        style={{
          flex: 1,
          flexDirection: "row",
          display: "flex",
          gap: 20,
          alignItems: "center",
          marginTop: 40,
          marginBottom: 20,
        }}
      >
        {[
          { description: "entradas", title: "Entradas", type: "C" },
          { description: "saídas", title: "Saídas", type: "D" },
        ].map((item, key) => {
          return (
            <div
              key={key}
              style={{
                background: "white",
                borderRadius: 25,
                borderColor: "var(--color-orange)",
                border: "1px solid",
                boxShadow: "10px 10px 5px 1px var(--color-gray)",
                padding: 10,
                flex: 1,
              }}
            >
              <h5
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: 26,
                  color: "var(--color-orange-light)",
                }}
              >
                {item.title}
              </h5>
              <Card
                title={`Nº de ${item.description}`}
                value={getNumberOfTransaaction(item.type)}
              />
              <Card
                title={`Valor de ${item.description}`}
                value={sumAll(item.type)}
              />
              <Card
                title={`Taxas de ${item.description}`}
                value={sumAllFee(item.type)}
              />
              <Card
                title={`Ticket médio de ${item.description}`}
                value={ticket(item.type)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MyTransactions;

const Card = ({ title, value }) => {
  return (
    <div style={{ flex: 1 }}>
      <span
        style={{
          fontWeight: "bold",
          fontSize: 20,
        }}
      >
        {title}:
      </span>

      <span style={{ fontSize: 24, marginLeft: 10 }}>{value}</span>
    </div>
  );
};
